// src/utils/constants/api.js
export const API = {
    BASE_URL: "https://teacherfy-gma6hncme7cpghda.westus-01.azurewebsites.net",
    ENDPOINTS: {
      OUTLINE: "/outline",
      GENERATE: "/generate",
      GENERATE_SLIDES: "/generate_slides",
      TRACK_ACTIVITY: "/track_activity"
    },
    TIMEOUT: 10000 // 10 seconds
  };